import React, { Component } from "react";

export default class BottomNavBar extends Component {
  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="footer">
            <section>
              <h2>About</h2>
              <ul>
                <li>Home</li>
                <li>Contact</li>
                <li>Technologies</li>
                <li>About</li>
              </ul>
            </section>
            <section>
              <h2>We are</h2>
              <ul>
                <li>Company</li>
                <li>Carear</li>
                <li>Out Team</li>
              </ul>
            </section>
            <section>
              <h2>Industries</h2>
              <ul>
                <li>All Industries</li>
                <li>Financial Services</li>
                <li>Technology</li>
                <li>More</li>
              </ul>
            </section>
            <section>
              <h2>Lang</h2>
              <ul>
                <li>Test Code Online</li>
                <li>HTML</li>
                <li>Python</li>
                <li>Go</li>
              </ul>
            </section>
          </div>
          <p>Copyright @2019, Conleaf Inc.</p>
        </footer>
      </React.Fragment>
    );
  }
}
