import React, { Component } from "react";

export default class About extends Component {
  state = {
    users: [
      { name: "abhi", age: 20 },
      { name: "shek", age: 21 },
      { name: "singh", age: 22 }
    ],
    title: "Users List"
  };

  makeMeYounger = () => {
    console.log("clicked");
    this.setState({
      users: [
        { name: "John", age: 10 },
        { name: "shek", age: 11 },
        { name: "singh", age: 12 }
      ]
    });
  };

  render() {
    return (
      <div>
        <button onClick={this.makeMeYounger}>Make Us 10 Year Younger</button>
        <br />
        <h1>{this.state.title}</h1>
        {/* <User age="20">abhi</User>
                <User age="20">shek</User> */}
        <div>
          <User
            name={this.state.users[0].name}
            age={this.state.users[0].age}
          ></User>
          <User
            name={this.state.users[1].name}
            age={this.state.users[1].age}
          ></User>
          <User
            name={this.state.users[2].name}
            age={this.state.users[2].age}
          ></User>
        </div>
      </div>
    );
  }
}

const User = props => {
  return (
    <div>
      Name: {props.name} | Age: {props.age}
    </div>
  );
};
