import React, { Component } from "react";
import NavBar from "./navbar";
import ReactDOM from "react-dom";
// import CodeLine from "./codeLine";
import Timer from "./timer";

// const $ = window.$;

const codeLineJson = {
  '10': "<",
  '100': "h",
  '1000': "ml",
  '5000': ">"
};

export default class Blog extends Component {
  constructor() {
    super();
    var today = new Date();

    this.state = {
      today: today,
      time: 0,
      play: 0,
      videoLength: 1000,
    };
  }

  _addCodeLine() {
    ReactDOM.render(
      <CodeLine codeLine={codeLineJson} name="abc" />,
      document.getElementById("newCodeEditor")
    );
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Timer/>
        <button onClick={this._addCodeLine} id="playPause">
          Run Event Based Video Editor
        </button>

        <div id="newCodeEditor" className="newCodeEditor"></div>
      </React.Fragment>
    );
  }
}

class CodeLine extends Component {
  constructor() {
    super();
    this.state = {
      time: 0,
      isPlaying: false
    };
    this.getTime = this.getTime.bind(this);
    this._onPlayPause = this._onPlayPause.bind(this);
  }

  componentDidMount() {
    this.getTime();
  }

  getTime() {
    let tTime = 0;
    setInterval(() => {
      if (this.state.isPlaying) {
        tTime = tTime + 10;
        this.setState({
          time: tTime
        });
      }
    }, 10);
  }

  _onPlayPause() {
    this.setState({ isPlaying: !this.state.isPlaying });
  }

  render() {
    return (
    // Object.keys(this.props.codeLine).map(key => 
      <div className="html-string" id="value">
      </div>
    );
  }
}
