import React from 'react';
import {bank,courses} from '../constants/questionBank';



class Quiz extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            qid:'',
            opid:'',
            qno:0,
            done:false,
            score:0,
            totalScore:bank.html.length,
            result:[],
            selected:false,
            user:'',
            isPlaying:false,
            time:0,
            isTimerRunning:false,
            course:'',
            questioBank:bank.html,
            showCourse:false

        };

        this.resetGame = this.resetGame.bind(this);
        this.setAns = this.setAns.bind(this);
        this.timer = this.timer.bind(this);
        this.createCourseList = this.createCourseList.bind(this);

    }

    setAns(qid,opid){
        let {id} = this.state.questioBank[this.state.qno].ans;
        
        
        if(opid===id && !this.state.selected){
            this.setState({
                score:this.state.score+1,
                "selected":true,
            });
            
        }else{
        if(opid!==id && this.state.selected){
            this.setState({
                score:this.state.score>0?this.state.score-1:0,
                "selected":false
            });
            
        }}

        this.setState({
            "qid":qid,
            "opid":opid,
        });
    }

    submitAns(qid){
        let o = {
            "qid":this.state.qid,
            "opid":this.state.opid,
            "qno":this.state.qno
        };
        if(this.state.qid && this.state.opid){
            this.state.result.push(o);
            this.setState({
                result:this.state.result
            });
        }

        if(this.state.qno<this.state.questioBank.length-1){
            let n = this.state.qno+1;
            this.setState({
            "qid":'',
            "opid":'',
            "selected":false,
            "qno":n,
            "time":15
            });
        }else{
            this.setState({done:true,qid:'',opid:'',});
        }

    }

    showResult(){
        return this.state.result.length>0 ? this.state.result.map(r=>(
            <div key={r.qid} >
                <h3>{this.state.questioBank[r.qno].qus.value}</h3>
                    {
                        r.opid ===this.state.questioBank[r.qno].ans.id
                    ?(<h4 style={{color:"green"}}>{this.state.questioBank[r.qno].ans.value}</h4>)
                    :(<h4 style={{color:"red"}}>{this.state.questioBank[r.qno][r.opid].value}</h4>)
                    }
            </div>
        )):'';
    }

    resetGame(){
        this.setState(
            this.state = {
                qid:'',
                opid:'',
                qno:0,
                done:false,
                score:0,
                totalScore:this.state.questioBank.length,
                result:[],
                showCourse:true,
                selected:false,
                time:0,
                course:'',
                questioBank:bank.html,
            }
        );
    }

    setUser(e){
        this.setState({
            [e.target.name]:e.target.value
        })

    }

    timer(){
            

            
        this.timerInterval = setInterval(() => {
            if (this.state.course !== '' && !this.state.showCourse) {
                if (this.state.isPlaying && this.state.time > 0 && !this.state.done) {
                    this.setState(prevState => ({
                        time: prevState.time - 1 
                    }
                    ))

                    console.log('time increase +1');
                }
                else { 
                    this.setState({ time: 0}) ;
                    console.log('time set 0');

                }
                
                if (this.state.isPlaying && this.state.time === 0 ) {
                    console.log('time === 0');

                    if (this.state.qno < this.state.totalScore - 1) {
                        this.setState({
                            time: 15,
                            qno: this.state.qno + 1
                        });

                    } else {
                        this.setState({
                            done: true,
                            isTimerRunning: false
                        });
                        clearInterval(this.timerInterval);
                    }

                }

            }
        }, 1000);
        
        
    }

    chooseCourse(c){
        if(!this.state.isTimerRunning){
            this.timer()
        }else{
            clearInterval(this.timerInterval); 
        }
        this.setState({
            course:c,
            questioBank:bank[c],
            isPlaying:true,
            time:15,
            showCourse:false,
            isTimerRunning:true
        });
    }

    createResultCard(){
        let {score,totalScore} = this.state;
        let mid = Math.floor(totalScore/2);
        let classname = '';
        let msg = '';
        if(score===totalScore){
            classname = 'winner-1-card';
            msg = 'Your are Champ :)'
        }
        if(score<totalScore && score>mid){
            classname = 'winner-2-card';
            msg = 'Congratulations';
        }
        if(score<=mid){
            classname = 'looser-card';
            msg = 'Bad Luck'
        }
        return (
            <div className={`
            quiz-card col l8 offset-l2 mrgt50px ${classname} winner-card`}>
    <div className="font-wight-bold white-text fsz3rem mrgt50px center-align">{msg}</div>
           <div className="white-text fsz5rem margin10px center-align">Score: {this.state.score+"/"+this.state.totalScore}</div>
           {/*  <section>{this.showResult()} </section> */}
           <button className="quiz-card-button" onClick={this.resetGame}>Play Again</button>
               </div>
           )
    }

    createUserName(){
        return (<div className="quiz-card col l8 offset-l2 mrgt100px">
        <h3 className="quiz-card-question mrgt50px fsz3rem font-wight-bold">Hey buddy enter your name</h3>
        <div className="col l8 offset-l2 mrgb50px mrgt50px">
            <input maxLength="20" name="user" className="user-input" onChange={(e)=>this.setUser(e)} type="text"/>
        </div>
        <div className="display-flex col s12">
        {
            this.state.user!==''
            ?<button className="quiz-card-button margin-auto mrgb30px " onClick={()=>this.setState({showCourse:true})}>Let's Play</button>
            :''
        }
        </div>
    </div>)
    }
    
    createCourseList(){
        let list =  courses.map(course=> (
            <div className="col l4">
               <div className="quiz-card col s12 w100   mrgt50px">
                <div className="font-wight-bold white-text fsz3rem mrgt50px center-align">{course.toUpperCase()}</div>
               <button className="quiz-card-button col s12 " onClick={()=>this.chooseCourse(course)}>Play</button>
                </div>
               </div>
        ));
        return (
            <div>
            <h3 className="quiz-card-question mrgt50px fsz3rem font-wight-bold">Choose your topic to play</h3>
                {list}
            </div>
        )

    }

    // componentDidMount(){
    //     if(!this.state.isTimerRunning){
    //         this.timer()
    //     }else{
    //         clearInterval(this.timerInterval); 
    //     }
        
    //     this.timer()
    // }

    // componentWillUnmount(){
    //     clearInterval(this.timerInterval);
    // }



    render(){
   
    let q = this.state.questioBank[this.state.qno];
    let ql = ( 
        <div className="quiz-card col l8 s12 offset-l2 mrgt50px">
            <h3 className="quiz-card-question">{q.qus.value}</h3>
            <div>
            <p className={`${(q.op1.id===this.state.opid)?'quiz-card-options active':'quiz-card-options'}`} onClick={()=>this.setAns(q.qus.id,q.op1.id)} ><span>A</span> {q.op1.value}</p> <br/>
            <p className={`${(q.op2.id===this.state.opid)?'quiz-card-options active':'quiz-card-options'}`} onClick={()=>this.setAns(q.qus.id,q.op2.id)} ><span>B</span> {q.op2.value}</p> <br/>
            <p className={`${(q.op3.id===this.state.opid)?'quiz-card-options active':'quiz-card-options'}`} onClick={()=>this.setAns(q.qus.id,q.op3.id)} ><span>C</span> {q.op3.value}</p> <br/>
            <p className={`${(q.op4.id===this.state.opid)?'quiz-card-options active':'quiz-card-options'}`} onClick={()=>this.setAns(q.qus.id,q.op4.id)} ><span>D</span> {q.op4.value}</p> <br/>
            </div>
            {
                this.state.qid && this.state.opid
                ?<button className="quiz-card-button" onClick={()=>this.submitAns(q.qus.id)}>Next</button>
                :''
            }
        </div>
    );
    return (
        <div className="row quiz-container black" style={{marginBottom:"0px"}}>
            <div className="col l4 s12">
            <div className="quiz-container-heading">Quizzee</div>
            {
                this.state.user!=='' && this.state.isPlaying
                ?<div>
                    <div className="welcome-title">Welcome</div>
                    <h3 className="white-text center-align welcome-name">{this.state.user} </h3>
                </div>
                :''
            }
            {
                this.state.time>0
                ?<h1 className="white-text center-align  play-time"> Time remainig {this.state.time} sec</h1>
                :''
            }
            
            </div>
            <div className="col l8 s12 quiz-container-main">
            {!this.state.isPlaying && !this.state.showCourse?this.createUserName():''}
            {this.state.showCourse?this.createCourseList() :''}
           {
               !this.state.done && this.state.user!=='' && this.state.isPlaying && !this.state.showCourse 
               ?(<section className="quiz-container-section"> {ql} </section>):''
           }
           {
               this.state.done && this.state.user!=='' && this.state.isPlaying && !this.state.showCourse
               ?this.createResultCard():''
           }
           
            </div>
        </div>
        
    )
    }
}

export default Quiz
