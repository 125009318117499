import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Editor from "./components/editor";
// import NavBar from "./components/navbar";
import Login from "./components/login";
import LandingPage from "./components/landingPage";
// import BottomNavBar from "./components/bottomNavBar";
import SignUp from "./components/signup";
import Blog from "./components/blog";
import About from "./components/about";
import newCodeEditor from "./components/newcodeeditor";
import RenderTest from "./components/rendertest";
import Quiz from "./components/quiz";
import Learn from './components/learn';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Quiz} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/learn" component={Learn}/>
          <Route exact path="/editor" component={Editor} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/about" component={About} />
          <Route exact path="/newCodeEditor" component={newCodeEditor} />
          <Route exact path="/rendertest" component={RenderTest} />
          <Route exact path="/quiz" component={Quiz} />
        </Switch>
        {/* <BottomNavBar /> */}
      </Router>
    </div>
  );
}

export default App;
