const html =  [
    {
        qus:{'id':'q1','value':' HTML stands for?'},
        op1:{'id':'op1',value:'Hyper Text Markup Language'},
        op2:{'id':'op2',value:'High Text Markup Language'},
        op3:{'id':'op3',value:'Hyper Tabular Markup Language'},
        op4:{'id':'op4',value:'None of these'},
        ans:{'id':'op1',value:'Hyper Text Markup Language'},
    },
    {
        qus:{'id':'q2','value':'which of the following tag is used to mark a begining of paragraph ?'},
        op1:{'id':'op1',value:'<TD></TD>'},
        op2:{'id':'op2',value:'<BR/>'},
        op3:{'id':'op3',value:'<P></P>'},
        op4:{'id':'op4',value:'<TR></TR>'},
        ans:{'id':'op3',value:'<P></P>'},
    },
    {
        qus:{'id':'q3','value':'Correct HTML tag for the largest heading is'},
        op1:{'id':'op1',value:'<head></head>'},
        op2:{'id':'op2',value:'<h6></h6>'},
        op3:{'id':'op3',value:'<heading></heading>'},
        op4:{'id':'op4',value:'<h1></h1>'},
        ans:{'id':'op4',value:'<h1></h1>'},
    },
    {
        qus:{'id':'q4','value':'The attribute of <form> tag'},
        op1:{'id':'op1',value:'Method'},
        op2:{'id':'op2',value:'Action'},
        op3:{'id':'op3',value:'Both (a)&(b)'},
        op4:{'id':'op4',value:'None of these'},
        ans:{'id':'op3',value:'Both (a)&(b)'},
    },
    {
        qus:{'id':'q5','value':'Markup tags tell the web browser'},
        op1:{'id':'op1',value:'How to organise the page'},
        op2:{'id':'op2',value:'How to display the page'},
        op3:{'id':'op3',value:'How to display message box on page'},
        op4:{'id':'op4',value:'None of these'},
        ans:{'id':'op1',value:'How to organise the page'},
    }
];

const css = [
    {
        qus:{id:'q1',value:"What does CSS stand for?"},
        op1:{id:'op1',value:"Creative Style Sheets"},
        op2:{id:'op2',value:"Cascading Style Sheets"},
        op3:{id:'op3',value:"Colorful Style Sheet"},
        op4:{id:'op4',value:"Computer Style Sheet"},
        ans:{id:'op2',value:"Cascading Style Sheets"}
    },
    {
        qus:{id:'q2',value:"If we want define style for an unique element, then which css selector will we use ?"},
        op1:{id:'op1',value:"id"},
        op2:{id:'op2',value:"text"},
        op3:{id:'op3',value:"class"},
        op4:{id:'op4',value:"name"},
        ans:{id:'op1',value:"id"}
    },
    {
        qus:{id:'q3',value:"If we don't want to allow a floating div to the left side of an element, which css property will we use ?"},
        op1:{id:'op1',value:"margin"},
        op2:{id:'op2',value:"clear"},
        op3:{id:'op3',value:"float"},
        op4:{id:'op4',value:"padding"},
        ans:{id:'op2',value:"clear"}
    },
    {
        qus:{id:'q4',value:"Suppose we want to arragnge five nos. of DIVs so that DIV4 is placed above DIV1. Now, which css property will we use to control the order of stack?"},
        op1:{id:'op1',value:"d-index"},
        op2:{id:'op2',value:"s-index"},
        op3:{id:'op3',value:"x-index"},
        op4:{id:'op4',value:"z-index"},
        ans:{id:'op4',value:"z-index"}
    },
    {
        qus:{id:'q5',value:"If we want to wrap a block of text around an image, which css property will we use ?"},
        op1:{id:'op1',value:"wrap"},
        op2:{id:'op2',value:"push"},
        op3:{id:'op3',value:"float"},
        op4:{id:'op4',value:"align"},
        ans:{id:'op3',value:"float"}
    }

];

const js = [
    {
        qus:{id:'q1',value:'Why so JavaScript and Java have similar name?'},
        op1:{id:'op1',value:'JavaScript is a stripped-down version of Java'},
        op2:{id:'op2',value:'JavaScript syntax is loosely based on Java'},
        op3:{id:'op3',value:'They both originated on the island of Java'},
        op4:{id:'op4',value:'None of the above'},
        ans:{id:'op2',value:'JavaScript syntax is loosely based on Java'}
    },
    {
        qus:{id:'q2',value:'When a user views a page containing a JavaScript program, which machine actually executes the script?'},
        op1:{id:'op1',value:"The User's machine running a Web browser"},
        op2:{id:'op2',value:'The Web server'},
        op3:{id:'op3',value:"A central machine deep within Netscape's corporate offices"},
        op4:{id:'op4',value:"None of the above"},
        ans:{id:'op1',value:"The User's machine running a Web browser"}
    },
    {
        qus:{id:'q3',value:"______ JavaScript is also called client-side JavaScript."},
        op1:{id:'op1',value:'Microsoft'},
        op2:{id:'op2',value:' Navigator'},
        op3:{id:'op3',value:'LiveWire'},
        op4:{id:'op4',value:'Native'},
        ans:{id:'op2',value:'Navigator'}
    },
    {
        qus:{id:'q4',value:"__________ JavaScript is also called server-side JavaScript."},
        op1:{id:'op1',value:'Microsoft'},
        op2:{id:'op2',value:' Navigator'},
        op3:{id:'op3',value:'LiveWire'},
        op4:{id:'op4',value:'Native'},
        ans:{id:'op3',value:'LiveWire'}
    },
    {
        qus:{id:'q5',value:"What are variables used for in JavaScript Programs?"},
        op1:{id:'op1',value:"Storing numbers, dates, or other values"},
        op2:{id:'op2',value:'Varying randomly'},
        op3:{id:'op3',value:'Causing high-school algebra flashbacks'},
        op4:{id:'op4',value:' None of the above'},
        ans:{id:'op1',value:'Storing numbers, dates, or other values'}
    }

];

export const bank = {
    'html':html,
    'css':css,
    'js':js
};

export const courses = ['html','css','js'];