import React, { Component } from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {SignupAction} from '../redux/action/authAction';
class SignUp extends Component {

  constructor(props){
    super(props);
    this.state = {
        userName:"",
        firstName:"",
        lastName:"",
        email:"",
        password:"",
        confirmPassword:"",
      signupData:{}


    };
    this.onSignup = this.onSignup.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }


  inputHandler(e){
    this.setState({[e.target.name]:e.target.value});
  }

  componentDidMount(){
    if(localStorage.getItem("isLogin")){
      this.props.history.push('/editor');
    }
  }

  componentWillReceiveProps(np){
    console.log(np);
    if(np!==undefined){
      let {signupData} = np; 
      if(signupData!==undefined && signupData.data.success){
        localStorage.setItem("token",signupData.data.data.token);
        localStorage.setItem("isLogin",true);
        this.props.history.push('/editor');
      }else{

      }
    }
  }


  onSignup(e){
    e.preventDefault();
    let data = {
      userName:this.state.userName,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      email:this.state.email,
      password:this.state.password,
    };
    let error = Object.keys(data);
    let msg = [];
    error.forEach(k=>{
      msg.push({k:k.toLowerCase()+" is empty"});
    });

    if(msg.length){
      alert("all field are required");
    }else{
      this.props.SignupAction(data);
    }


  }
  render() {
    return (
      <React.Fragment>
        <h1>Welcome Back!</h1>
        <form onSubmit={this.onSignup}>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-font"></i>
              </div>
              <input onChange={this.inputHandler} type="text" name="userName" id="name" placeholder="username" />
            </div>
          </div>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-font"></i>
              </div>
              <input onChange={this.inputHandler} type="text" name="firstName" id="name" placeholder="firstname" />
            </div>
          </div>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-font"></i>
              </div>
              <input onChange={this.inputHandler} type="text" name="lastName" id="name" placeholder="lastname" />
            </div>
          </div>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-user"></i>
              </div>
              <input onChange={this.inputHandler} type="text" name="email" id="email" placeholder="email" />
            </div>
          </div>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-unlock-alt"></i>
              </div>
              <input onChange={this.inputHandler} name="password" type="password" id="password" placeholder="password" />
            </div>
          </div>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-unlock-alt"></i>
              </div>
              <input onChange={this.inputHandler} name="confirmPassword" type="password" id="password" placeholder="confirm password" />
            </div>
          </div>
          <div class="form_footer">
            <button class="signup_button">Signup</button>
          </div>
          <span class="or">
            <hr />
            or
            <hr />
          </span>
          <button class="login">Login, already have an account</button>
          <div class="socialicon">
            <i class="fab fa-facebook-square"></i>
            <i class="fab fa-google"></i>
            <i class="fab fa-github"></i>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  SignupAction:PropTypes.func.isRequired
}

const mapStateToProps = state=>({
  signupData:state.signup
});


export default connect(mapStateToProps,{SignupAction})(SignUp);
