import {combineReducers} from 'redux';

import loginReducer from './loginReducer';
import signupReducer from './signupReducer';
import userPanelReducer from './userReducer';

const rootReducres = combineReducers({
    login:loginReducer,
    user:userPanelReducer,
    signup:signupReducer,
});

export default rootReducres ;
