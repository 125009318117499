import {SIGNUP_TYPE,SIGNUP_ERROR_TYPE} from '../type/types';

const initialState = {};

export default function(state=initialState,action){
    switch(action.type){
    case SIGNUP_TYPE : return {...state,data:action.payload};
    case SIGNUP_ERROR_TYPE : return {...state,data:action.payload};
    default: return state;
    }
}