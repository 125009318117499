import React, { Component } from "react";

export default class Timer extends Component {
  
  constructor() {
    super();
    this.state = {
      time: 0,
      isPlaying: false
    };
    this.getTime = this.getTime.bind(this);
    this._onPlayPause = this._onPlayPause.bind(this);
  }

  makeStringToObj(){
    var code = "<div><h1>Hello</h1><h5>world</h5><p>by vinod</p></div>";
    var ca = code.split("");
    var co = {};
    let t = 100;
    ca.forEach(c=>{
      co[t*Math.floor(Math.random()*10)]=c;
      t+=100;
    });
    this.setState({codeObj:co});
  }

  componentDidMount() {
    this.getTime();
  }

  getTime() {
    let tTime = 100;
    setInterval(() => {
      if (this.state.isPlaying) {
        let limit = Object.keys(this.state.codeObj)[this.state.codeObj-1];
        console.log(limit);
        tTime = tTime + 100;
        let c = this.state.codeObj[tTime];
        this.setState({
          time: tTime
        });
        if(c!==undefined){
          let fulls = this.state.tempString+c;
          this.setState({tempString:fulls,currentString:c});
        }
      }
    },100);
  }

  _onPlayPause() {
    this.setState({ isPlaying: !this.state.isPlaying });
  }

  showCode(){
    if(this.state.isPlaying){
      let c = this.state.codeObj[this.state.time];
      if(c!==undefined){
        let s = this.state.tempString+c;
        this.setState({tempString:s});
        console.log('//////////',this.state.tempString);
      }
    }
  }


  render() {
    return (
      <div>
        <button onClick={this._onPlayPause}>play/pause</button>
        {this.state.time}
      </div>
    );
  }
}
