import axios from 'axios';
import * as TYPE from '../type/types';
import * as URL from '../../constants/url';

export const LoginAction = DATA => dispatch => {
    var REQ_OPTIONS = {
        url: URL.LOGIN_API,
        method: URL.POST_METHOD,
        data: DATA
    };
    dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: true } });
    axios(REQ_OPTIONS)
        .then(data => data.data)
        .then(Response => {
            dispatch({ type: TYPE.LOGIN_TYPE, payload: Response });
            dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: false } });
        }).catch(err => {
            dispatch({type:TYPE.LOGIN_ERROR_TYPE,payload:Response});
            dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: false } });
        });
};

export const SignupAction = DATA => dispatch => {
    var REQ_OPTIONS = {
        url: URL.SIGNUP_API,
        method: URL.POST_METHOD,
        data: DATA
    };
    dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: true } });
    axios(REQ_OPTIONS)
        .then(data => data.data)
        .then(Response => {
            dispatch({ type: TYPE.SIGNUP_TYPE, payload: Response });
            dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: false } });
        }).catch(err => {
            dispatch({type:TYPE.SIGNUP_ERROR_TYPE,payload:Response});
            dispatch({ type: TYPE.LOADING_TYPE, payload: { isLoading: false } });
        });
};