import React, { Component } from "react";
import NavBar from "./navbar";
import BottomNavBar from "./bottomNavBar";
class LandingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="join-us">
          <p>
            Join over <strong> million developers.</strong>
            <br /> Practice coding, prepare for interviews, and get hired.
          </p>
          <button>Sign Up and Code</button>
        </div>

        <div className="our-partner">
          <ul>
            <li>Booking.com</li>
            <li>Goldman Sachs</li>
            <li>Simplyblock</li>
            <li>buyucoin</li>
            <li>Syncrasytech</li>
            <li>Blockriti</li>
          </ul>
        </div>

        <div className="code-hire">
          <section className="left">
            <h6>Conleaf developers</h6>
            <h3>Code & Get Hired</h3>
            <br />
            <p>
              Join over 5 million developers, improve your coding skills, and
              find the best job.
            </p>
            <button>Sign Up & Code</button>
            <br />
            <br />
            <hr />
          </section>
          <section className="right">
            <h6>Conleaf for work</h6>
            <h3>Hire Talent</h3>
            <br />
            <p>
              Conleaf for Work is the leading end-toend technical recruiting
              platform for hiring developers.
            </p>
            <button>Learn More</button>
            <br />
            <br />
            <hr />
          </section>
        </div>

        <div className="poster">
          <h1>Conleaf Projects for Data Science</h1>
          <br />
          <p>
            Identify and assess top data science candidates through
            project-based, real-world challenges.
          </p>

          <button>Learn More</button>
        </div>
        <BottomNavBar />
      </React.Fragment>
    );
  }
}

export default LandingPage;
