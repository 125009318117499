import React, { Component } from "react";

export default class TaskDisc extends Component {
  render() {
    return (
      <React.Fragment>
        <div class="task-container">
          <div className="task-disc">
            <h2 className="topic-head">
              <i class="fab fa-leanpub"></i> Tables
            </h2>
            <div className="task-disc1">
              <p className="sub-head">Lorem ipsum</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum eos deleniti dolorem saepe repellat nam adipisci,
                doloremque maiores laborum similique sit ipsum maxime corporis
                reprehenderit odit dignissimos ab, dicta veritatis! <br/> <br/>Ipsum
                recusandae ipsam voluptatum illum tempore vitae odio temporibus
                est veritatis. Culpa est ratione nobis ipsum <br />
              </p>
            </div>
            <br />
            <h2 class="topic-head"><i class="fas fa-tools"></i> Instructions</h2>
            <div class="task-disc1">
              <p>
                <li>Chagne in Details</li>
                <li>Put new Value of Tables</li>
              </p>
            </div>
            <br />
            <br />
            <div class="topic-head">
              <h2><i class="fas fa-people-carry"></i> Community Forums</h2>
            </div>
            <div class="task-disc1">
              <p>
                qui cumque atque. Doloremque id facilis quam repellendus,
                dolorum, vel architecto animi repellat est ab omnis dignissimos
                voluptatibus quidem. Itaque id veritatis sunt natus inventore
                debitis doloribus, harum suscipit repellendus aspernatur
                recusandae, incidunt quod. Earum architecto eum obcaecati
                deleniti
                <br />
                <br /> autem adipisci quibusdam accusamus libero laboriosam,
                culpa quos, eaque recusandae perspiciatis dignissimos ipsam?
                Commodi quaerat, velit dolor officia nesciunt labore illum in
                cumque ad molestiae! Fugit beatae magni, quo quidem incidunt
                animi deleniti aut nam id eveniet distinctio tempora, error
                consectetur quibusdam, quas dolores aspernatur voluptatibus vel
                vitae. Debitis minus quasi quibusdam explicabo culpa aliquid qui
                vero sint, assumenda, nostrum maxime tempora repellat rerum eius
                magni dolorum saepe ex porro odit consectetur praesentium id
                itaque laborum perferendis. Ullam maxime quod perferendis rerum
                placeat adipisci voluptatum, iure quibusdam? Praesentium
                quibusdam cumque velit minima eveniet nesciunt, voluptas, amet
                commodi dolor recusandae perferendis esse. Perspiciatis tempora
                tenetur quas nam vel ab blanditiis, dolorum aliquid mollitia.
              </p>

              <div className="wrapper">
                <button className="execute-code">Run Code</button>
                <button className="assist">Assist</button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
