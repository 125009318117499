import {LOGIN_TYPE, LOGOUT_TYPE} from '../type/types';

const initialState = {};

export default function (state = initialState,action){
    switch (action.type){
        case LOGIN_TYPE:return{...state,data:action.payload};
        case LOGOUT_TYPE :return{...state,data:action.payload};
        default:return state;
    }
}
