import React, { Component } from "react";
import TaskDisc from "./taskDisc";

export default class Editor extends Component {
  componentDidMount() {
    this._appJquery();
  }

  _appJquery() {
    var html = document.getElementById("html");
    // var css = document.getElementById("css");
    // var js = document.getElementById("js");
    var code = document.getElementById("code").contentWindow.document;

    document.body.onkeyup = function() {
      //   ColorTag();

      code.open();
      code.writeln(
        html.value
        // +
        // "<style>" +
        // css.value +
        // "</style>" +
        // "<script>" +
        // js.value +
        // "</script>"
      );
      code.close();
    };
  }

  render() {
    let defalut_textare_value =
      '<!DOCTYPE html> <html> <style> body, html { height: 100%; margin: 0; } .bgimg { background-image: url(\'https://images.pexels.com/photos/257360/pexels-photo-257360.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940\'); height: 100%; background-position: center; background-size: cover; position: relative; color: white; font-size: 25px; } .topleft { position: absolute; top: 0; left: 16px; } .bottomleft { position: absolute; bottom: 0; left: 16px; } .middle { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; } hr { margin: auto; width: 40%; } </style> <body> <div class="bgimg"> <div class="topleft"> <p>Logo</p> </div> <div class="middle"> <h1>COMING SOON</h1> <hr> <p>35 days left</p> </div> <div class="bottomleft"> <p>Some text</p> </div> </div> </body> </html>';
    return (
      <React.Fragment>
        <div className="learn">
          <div className="task-container">
            <TaskDisc />
          </div>
          <div className="Code-Editor">
            <div className="editor">
              <div className="editor-head">
                <div class="avi-test">CODE EDITOR</div>
              </div>
              <textarea className="editor-panel" id="html" placeholder="HTML">
                {defalut_textare_value}
              </textarea>
              {/* <ul>
              <div style={{ backgroundColor: "rgb(0,0,0)" }}>
                <li className="first">Result</li>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(56, 55, 55)",
                  padding: "0px 0px 0 10px"
                }}
              >
                <li className="non-selected">Debug</li>
              </div>
              <li className="non-selected">Terminal</li>
              <li className="non-selected">log</li>
            </ul> */}
              {/*  <textarea className="terminal" id="html" placeholder="usr@admin:~/code/html/project$ "></textarea>  */}
            </div>
            <iframe className="code" title="code" id="code"></iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
