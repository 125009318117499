import React from "react";
import NavBar from "./navbar";
import BottomNavBar from "./bottomNavBar";
import {connect} from 'react-redux';
import {LoginAction} from '../redux/action/authAction'; 
import PropTypes from 'prop-types';

class Login extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        email:"",
        password:"",
        loginData:{}


    };
    this.onLogin = this.onLogin.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }


  inputHandler(e){
    this.setState({[e.target.name]:e.target.value});
  }

  componentDidMount(){
    if(localStorage.getItem("isLogin")){
      this.props.history.push('/editor');
    }
  }

  componentWillReceiveProps(np){
    console.log(np);
    if(np!==undefined){
      let {loginData} = np; 
      if(loginData!==undefined && loginData.data!==undefined && loginData.data.success ){
          localStorage.setItem("token",loginData.data.data.token);
          localStorage.setItem("isLogin",true);
          this.props.history.push('/editor');
      }else{

      }
    }
  }


  
  onLogin(e){
    e.preventDefault();
    let data = {
      email:this.state.email,
      password:this.state.password,
    };
    let error = Object.keys(data);
    let msg = [];
    error.forEach(k=>{
      if(data[k]===""){
        msg.push({[k]:k.toLowerCase()+" is empty"});
      }
    });

    console.log(msg,data);

    if(msg.length){
      alert("enter username or password");
    }else{
      this.props.LoginAction(data);
    }

  }
  render(){
  return (
    <React.Fragment>
      <NavBar />
      <div class="login-div">
        <h1>Welcome Back </h1>
        <form onSubmit={this.onLogin}>
          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-user"></i>
              </div>
              <input type="text" name="email" onChange={this.inputHandler} id="email" />
            </div>
          </div>

          <div class="input_head">
            <div class="input">
              <div class="icon_background">
                <i class="fas fa-unlock-alt"></i>
              </div>
              <input type="text" name="password"  id="password" onChange={this.inputHandler} />
            </div>
          </div>
          <div class="form_footer">
            <span class="forget_password">
              forget password?<button class="login_button">Login</button>
            </span>
          </div>
          <span class="or">
            <hr />
            or
            <hr />
          </span>
          <button class="register">Register</button>
        </form>
      </div>
      <BottomNavBar />
    </React.Fragment>
  );
}
}

Login.propTypes = {
  LoginAction:PropTypes.func.isRequired
}

const mapStateToProps =state=>({
  loginData:state.login
});

export default connect(mapStateToProps,{LoginAction})(Login);
