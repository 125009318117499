import React from "react";
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';

class NavBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLogin:false
    };
  }

  componentDidMount(){
    if(localStorage.getItem("isLogin")){
      this.setState({isLogin:true});
    }
  }

  componentWillReceiveProps(np){
    console.log(np);
    if(np!==undefined){
      let {loginData} = np; 
      if(loginData!==undefined && loginData.data.success){
        localStorage.setItem("token",loginData.data.data.token);
        localStorage.setItem("isLogin",true);
        this.props.history.push('/editor');
      }else{

      }
    }
  }


  render(){
  return (
    <div className="header">
      <div className="logo">Conleaf</div>
      <span className="menu-sidebar">
        <i className="fa fa-bars" aria-hidden="true"></i>
      </span>
      <nav className="main-nav">
        {!this.state.isLogin
        ?
        <ul>
          <li>
            <NavLink to="/learn">Learn</NavLink>
          </li>
          <li>
            <NavLink to="/Blog">Blog</NavLink>
          </li>
          <li>
            <NavLink to="/About">About</NavLink>
          </li>
          <li>
            <NavLink to="/login">Login</NavLink>
          </li>
          <li>
            <NavLink to="SignUp">Signup</NavLink>
          </li>
        </ul>
        :
        <ul>
        <li>
          <NavLink to="/learn">Learn</NavLink>
        </li>
        <li>
          <NavLink to="/login">Logout</NavLink>
        </li>
        
      </ul>
        }
       
      </nav>
    </div>
  );
}
}


const mapStateToProps =state=>({
  loginData:state.login
});

export default connect(mapStateToProps,null)(NavBar);
