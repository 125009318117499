import React from "react";
// import TaskDisc from "./taskDisc";
// import Editor from "./editor";
import "./learCss.css";

export default function Learn() {
  return (
    <React.Fragment>
      <div className="learn-head">
        <h1>Select A language</h1>
      </div>
      <div className="learn">
        <img alt="html" className="learn-img" src="https://cdn.codecademy.com/assets/components/cards/path-card/5b55e668646caa552f8e4d1d.svg"/>
        <div className="product">
          <h2><a href="/editor">Learn HTML</a></h2>
          <div className="disc">Go from no-code to an in-demand junior web developer, at a fraction of the cost of a bootcamp. Start with the front-end by learning HTML, CSS, and JavaScript. Then, master the back-end and APIs to round out your full-stack skills.</div>
          <span className="lang">HTML</span><span className="lang">CSS</span><span className="lang">JS</span>
        </div>
      </div>
      <div className="learn">
        <img alt="react" className="learn-img" src="https://cdn.codecademy.com/assets/components/cards/path-card/5b520caa1d176d21f5a65a61.svg"/>
        <div className="product">
          <h2>Learn React</h2>
          <div className="disc">Go from no-code to an in-demand junior web developer, at a fraction of the cost of a bootcamp. Start with the front-end by learning HTML, CSS, and JavaScript. Then, master the back-end and APIs to round out your full-stack skills.</div>
          <span className="lang">Beginner</span><span className="lang">intermediate</span><span className="lang">Advance</span>
        </div>
      </div>
      <div className="learn">
        <img alt="ViewJS" className="learn-img" src="https://cdn.codecademy.com/assets/components/cards/path-card/5b55e668646caa552f8e4d1d.svg"/>
        <div className="product">
          <h2>Learn ViewJS</h2>
          <div className="disc">Go from no-code to an in-demand junior web developer, at a fraction of the cost of a bootcamp. Start with the front-end by learning HTML, CSS, and JavaScript. Then, master the back-end and APIs to round out your full-stack skills.</div>
          <span className="lang">Beginner</span><span className="lang">intermediate</span><span className="lang">Advance</span>          
        </div>
      </div>
    </React.Fragment>
  );
}
