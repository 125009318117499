import React, { Component } from 'react'

export default class RenderTest extends Component {

    constructor(){
        super();
        this.state={
            "data":
            {id:1, className:"abc",}
        }
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
