import {createStore,applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducer/rootReducer';

// const initialState = {};

const middleware = [thunk];
console.log('inside store')
const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middleware),
    //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
);  

export default store;

