import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import codeLine from "./codeLine";
// import NavBar from "./navbar";
import LineOfCode from "./lineOfCode.json";
const $ = window.$;

const codeLineJson = {
  "10": "<",
  "100": "h",
  "400": "t",
  "500": "m",
  "800": "l",
  "900": ">",
  "1000": "\n",
  "950": " ",
  "1100": "<",
  "1200": "h",
  "1500": "t",
  "1800": "m",
  "1900": "l",
  "2000": ">",
  "2500": "<",
  "2900": "h",
  "3500": "m",
  "3800": "l",
  "4000": ">",
  "4050": "\n",
  "5100": "<",
  "5500": "h",
  "5900": "t",
  "6500": "m",
  "6800": "l",
  "7000": ">",
  "7100": "<",
  "7200": "h",
  "7500": "m",
  "8000": "l",
  "8200": ">",
  "8500": "<",
  "8900": "h",
  "9500": "m",
  "9800": "l",
  "9900": ">",
  "10000": "\n"
};

// const defaultCode =
//   '<!DOCTYPE html> <html> <style> body, html { height: 100%; margin: 0; } .bgimg { background-image: url(\'https://images.pexels.com/photos/257360/pexels-photo-257360.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940\'); height: 100%; background-position: center; background-size: cover; position: relative; color: white; font-size: 25px; } .topleft { position: absolute; top: 0; left: 16px; } .bottomleft { position: absolute; bottom: 0; left: 16px; } .middle { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; } hr { margin: auto; width: 40%; } </style> <body> <div class="bgimg"> <div class="topleft"> <p>Logo</p> </div> <div class="middle"> <h1>COMING SOON</h1> <hr> <p>35 days left</p> </div> <div class="bottomleft"> <p>Some text</p> </div> </div> </body> </html>';
export default class newCodeEditor extends Component {
  constructor() {
    super();
    this.state = {
      pValue: "",
      time: 0,
      isPlaying: false,
      jsonInner: {}
    };
    this.getTime = this.getTime.bind(this);
    this._onPlayPause = this._onPlayPause.bind(this);
  }

  componentDidMount() {
    this.getTime();
    this._jQuery();
  }

  getTime() {
    let limit = Object.keys(codeLineJson)[Object.keys(codeLineJson).length - 1];
    console.log("limit = ", limit);
    let tTime = 0;
    var value = "";
    setInterval(() => {
      if (this.state.isPlaying) {
        tTime = tTime + 10;
        var c = codeLineJson[this.state.time];

        if (c !== undefined) {
          console.log(c, " & ", this.state.time);

          this.state.jsonInner[this.state.time] = c;

          console.log(this.state.jsonInner);

          value += c;
          this.setState({
            pValue: value
          });
        }
        this.setState({
          time: tTime
        });

        if (
          this.state.time >
          Object.keys(codeLineJson)[Object.keys(codeLineJson).length - 1]
        ) {
          this.setState({
            isPlaying: false
          });
        }
      }
    }, 10);
  }

  _onPlayPause() {
    // ReactDOM.render(<div>bagdf</div>, document.getElementById("newCodeEditor"));
    var valIsPlaying;
    if (!this.state.isPlaying) {
      valIsPlaying = true;
    } else {
      valIsPlaying = false;
    }
    this.setState({ isPlaying: valIsPlaying });
  }

  // _onPause() {
  //   if (this.state.isPlaying) {
  //     this.setState({ isPlaying: false });
  //   }
  // }

  _jQuery() {
    var html = $("#newCodeEditor");
    // var html=document.getElementById("newCodeEditor");
    var code = document.getElementById("result").contentWindow.document;
    document.body.onkeyup = function() {
      // var html=$("#newCodeEditor").html();
      //   ColorTag();
      console.log("value = ", html);

      code.open();
      code.writeln(
        html.text()
        // +
        // "<style>" +
        // css.value +
        // "</style>" +
        // "<script>" +
        // js.value +
        // "</script>"
      );
      code.close();
    };
    // console.log(html);
    $(document).ready(() => {
      // html.text(defaultCode);
      console.log(LineOfCode);
    });

    $()
  }

  // _keyPress(e) {
  //   console.log(String.fromCharCode(e.keyCode));
  // }

  _returnDiv = vla => {
    //   if (this.state.pValue==="\na") {
    //     return <UserGreeting />;
    //   }
    //   return <GuestGreeting />;
    // }
    if(this.state.pValue === ""){
      return 0;
    }else if (this.state.pValue !== "<") {
      console.log("true ture tt")
      return <b>{this.state.pValue}</b>;
    }
    else {
      return <pre>{this.state.pValue}</pre>;
    }
  };

  render() {
    return (
      <div>
        <div className="boxes">
          <div
            onClick={this._onPause}
            id="newCodeEditor"
            className="newCodeEditor html-string"
            contentEditable="true"
            onKeyDown={this._keyPress}
          >
            <pre>
              <span>
                {/* {this.state.pValue} */}
                <this._returnDiv />
              </span>
            </pre>
          </div>

          <iframe title="result" className="result" id="result"></iframe>
        </div>

        <div className="audio-div">
          <audio
            controls
            onPause={this._onPlayPause}
            onPlay={this._onPlayPause}
            className="audio-controller"
          >
            <source src="https://www.computerhope.com/jargon/m/example.mp3" />
          </audio>
        </div>

        {/* <p id=""> */}
        {/* {this.state.pValue} ||  */}
        {/* time {this.state.time} */}
        {/* </p> */}

        {/* <button>Click to append</button> */}

        {/* <button onClick={this._onPlayPause}>Click to play and pause</button>
        <div contentEditable="true"></div> */}
      </div>
    );
  }
}
